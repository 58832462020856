document.addEventListener('DOMContentLoaded', () => {
  const { MaskInput } = Maska;

  new MaskInput('[data-maska]');

  //Validation form and send form
  const inputNamePresentation = document.querySelector(
    '.presentation__form-input-name'
  );
  const inputPhonePresentation = document.querySelector(
    '.presentation__form-input-phone'
  );
  const buttonSubmitPresentation = document.querySelector(
    '.presentation__form-btn'
  );

  ///ModalThanks
  const modalThanksPresentation =
    document.querySelector('.modal--thanks');

  const body = document.body;

  ///Send Form
  buttonSubmitPresentation.addEventListener('click', (e) => {
    if (
      inputPhonePresentation.value.length < 16 ||
      inputNamePresentation.value.length < 2
    ) {
      if (inputNamePresentation.value.length < 2)
        inputNamePresentation.classList.add('error');
      if (inputPhonePresentation.value.length < 16)
        inputPhonePresentation.classList.add('error');
    } else {
      modalThanksPresentation.classList.add('modal--active');
      body.style.overflow = 'hidden';
      setTimeout(() => {
        inputNamePresentation.value = '';
        inputPhonePresentation.value = '';
        body.style.overflow = 'auto';
        modalThanksPresentation.classList.remove('modal--active');

        ///Send data on mail
      }, 2000);
    }
  });

  ///Name control
  inputNamePresentation.oninput = function () {
    if (inputNamePresentation.value.length < 2) {
      inputNamePresentation.classList.add('novalid');
    } else {
      inputNamePresentation.classList.remove('novalid');
      inputNamePresentation.classList.remove('error');
    }
  };

  ///Phone control
  inputPhonePresentation.oninput = function () {
    if (inputPhonePresentation.value.length < 16) {
      inputPhonePresentation.classList.add('novalid');
    } else {
      inputPhonePresentation.classList.remove('novalid');
      inputPhonePresentation.classList.remove('error');
    }
  };
});
