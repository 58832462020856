document.addEventListener('DOMContentLoaded', () => {
  const { MaskInput } = Maska;

  new MaskInput('[data-maska]');

  /* Аккордеон в блоке Способы Приобретения*/
  const accordionItem = document.querySelectorAll(
    '.acquisition-methods__item'
  );

  accordionItem.forEach((elem) => {
    elem.addEventListener('click', () => {
      if (
        elem.classList.contains('acquisition-methods__item--active')
      ) {
        elem.classList.remove('acquisition-methods__item--active');
      } else {
        accordionItem.forEach((item) => {
          item.classList.remove('acquisition-methods__item--active');
        });
        elem.classList.add('acquisition-methods__item--active');
      }
    });
  });

  //Validation form and send form
  const inputNameAcquisition = document.querySelector(
    '.acquisition-methods--input--name'
  );
  const inputPhoneAcquisition = document.querySelector(
    '.acquisition-methods--input--phone'
  );
  const buttonSubmitAcquisition = document.querySelector(
    '.acquisition-methods--submit'
  );

  ///ModalThanks
  const modalThanksAcquisition =
    document.querySelector('.modal--thanks');

  const body = document.body;

  ///Send Form
  buttonSubmitAcquisition.addEventListener('click', (e) => {
    if (
      inputPhoneAcquisition.value.length < 16 ||
      inputNameAcquisition.value.length < 2
    ) {
      if (inputNameAcquisition.value.length < 2)
        inputNameAcquisition.classList.add('error');
      if (inputPhoneAcquisition.value.length < 16)
        inputPhoneAcquisition.classList.add('error');
    } else {
      modalThanksAcquisition.classList.add('modal--active');
      body.style.overflow = 'hidden';
      setTimeout(() => {
        inputNameAcquisition.value = '';
        inputPhoneAcquisition.value = '';
        body.style.overflow = 'auto';
        modalThanksAcquisition.classList.remove('modal--active');

        ///Send data on mail
      }, 2000);
    }
  });

  ///Name control
  inputNameAcquisition.oninput = function () {
    if (inputNameAcquisition.value.length < 2) {
      inputNameAcquisition.classList.add('novalid');
    } else {
      inputNameAcquisition.classList.remove('novalid');
      inputNameAcquisition.classList.remove('error');
    }
  };

  ///Phone control
  inputPhoneAcquisition.oninput = function () {
    if (inputPhoneAcquisition.value.length < 16) {
      inputPhoneAcquisition.classList.add('novalid');
    } else {
      inputPhoneAcquisition.classList.remove('novalid');
      inputPhoneAcquisition.classList.remove('error');
    }
  };
});
