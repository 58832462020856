function alignStagesSlides() {
    const slides = document.querySelectorAll('.stages__slide');
    const heights = Array.from(slides, slide => {
        slide.style.height = 'auto';
        return slide.offsetHeight;
    });
    const maxHeight = Math.max(...heights);
    slides.forEach(slide => slide.style.height = `${maxHeight}px`);

    const textElements = document.querySelectorAll('.stages__slide-text');
    const infElements = document.querySelectorAll('.stages__slide-inf');
    
    [...textElements, ...infElements].forEach(el => el.style.marginTop = '');
    
    const maxTextTop = Math.max(...Array.from(textElements, el => el.offsetTop));
    const maxInfTop = Math.max(...Array.from(infElements, el => el.offsetTop));
    
    textElements.forEach(el => {
        const diff = maxTextTop - el.offsetTop;
        if (diff > 0) el.style.marginTop = `${diff}px`;
    });
    
    infElements.forEach(el => {
        const diff = maxInfTop - el.offsetTop;
        if (diff > 0) el.style.marginTop = `${diff}px`;
    });
}

document.addEventListener("DOMContentLoaded", () => {
    // stages__slider
    var stages = new Swiper(".stages__swiper", {
        slidesPerView: 1,
        spaceBetween: 19,
        autoHeight: false,
        navigation: {
            nextEl: ".stages__slider-next",
            prevEl: ".stages__slider-prev",
        },
        breakpoints: {
            501: {
                slidesPerView: 2,
            },
            1025: {
                slidesPerView: 4,
            },
        },
        on: {
            init: function () {
                setTimeout(alignStagesSlides, 100);
            },
            resize: alignStagesSlides,
            slideChange: alignStagesSlides
        }
    });
});