document.addEventListener('DOMContentLoaded', () => {
  const { MaskInput } = Maska;

  new MaskInput('[data-maska]');

  ///Validation Form
  const inputNameSectionForm = document.querySelector(
    '.section-form--input--name'
  );
  const inputPhoneSectionForm = document.querySelector(
    '.section-form--input--phone'
  );
  const buttonSubmitSectionForm = document.querySelector(
    '.section-form--input--submit'
  );

  ///ModalThanks
  const modalThanksSectionForm =
    document.querySelector('.modal--thanks');

  const body = document.body;

  ///Send Form
  buttonSubmitSectionForm.addEventListener('click', (e) => {
    if (
      inputPhoneSectionForm.value.length < 16 ||
      inputNameSectionForm.value.length < 2
    ) {
      if (inputNameSectionForm.value.length < 2)
        inputNameSectionForm.classList.add('error');
      if (inputPhoneSectionForm.value.length < 16)
        inputPhoneSectionForm.classList.add('error');
    } else {
      modalThanksSectionForm.classList.add('modal--active');
      body.style.overflow = 'hidden';
      setTimeout(() => {
        inputNameSectionForm.value = '';
        inputPhoneSectionForm.value = '';
        body.style.overflow = 'auto';
        modalThanksSectionForm.classList.remove('modal--active');

        ///Send data on mail
      }, 2000);
    }
  });

  inputNameSectionForm.oninput = function () {
    if (inputNameSectionForm.value.length < 2) {
      inputNameSectionForm.classList.add('novalid');
    } else {
      inputNameSectionForm.classList.remove('novalid');
      inputNameSectionForm.classList.remove('error');
    }
  };

  inputPhoneSectionForm.oninput = function () {
    if (inputPhoneSectionForm.value.length < 16) {
      inputPhoneSectionForm.classList.add('novalid');
    } else {
      inputPhoneSectionForm.classList.remove('novalid');
      inputPhoneSectionForm.classList.remove('error');
    }
  };
});
