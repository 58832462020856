<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref, reactive, watch } from "vue";

let archives = [];
const swiperEl = ref(null);
let x = 0;

const activeInfo = reactive({
  activeSelect: false,
  activeArchive: "",
  loading: false,
  activeMonth: 0,
  activeStage: 3,
  activeArchives: ["2023", "2024", "2025"],
  activeSelectStage: false,
  stages: [
    {
      number: 1,
      active: false,
      years: ["2020", "2021", "2022", "2023"],
      month: ["Январь", "Июнь"], // Месяцы начала и окончания периода соответственно
    },
    {
      number: 2,
      active: false,
      years: ["2023", "2024"],
      month: ["Январь", "Декабрь"],
    },
    {
      number: 3,
      active: true,
      years: ["2025"],
      month: ["Январь", "Февраль"],
    },
  ],
  month: [
    {
      name: "Январь",
      visited: true,
      active: false,
      exists: true,
    },
    {
      name: "Февраль",
      visited: true,
      active: true,
      exists: true,
    },
    {
      name: "Март",
      visited: false,
      active: false,
      exists: false,
    },
    {
      name: "Апрель",
      visited: false,
      active: false,
      exists: false,
    },
    {
      name: "Май",
      visited: false,
      active: false,
      exists: false,
    },
    {
      name: "Июнь",
      visited: false,
      active: false,
      exists: false,
    },
    {
      name: "Июль",
      visited: false,
      active: false,
      exists: false,
    },
    {
      name: "Август",
      visited: false,
      active: false,
      exists: false,
    },
    {
      name: "Сентябрь",
      visited: false,
      active: false,
      exists: false,
    },
    {
      name: "Октябрь",
      visited: false,
      active: false,
      exists: false,
    },
    {
      name: "Ноябрь",
      visited: false,
      active: false,
      exists: false,
    },
    {
      name: "Декабрь",
      visited: false,
      active: false,
      exists: false,
    },
    {},
  ],
  descriptions: [
    {
      year: "2025",
      month: "Январь",
      text: "7 секция- Усиление грунтов основания выполнено на 80 % </br></br>\
      8 секция - Выполнено бетонирование фундаментной плиты и установка башенного крана </br></br>\
      9 секция - Усиление грунтов основания выполнено на 35 %",
    },
    {
      year: "2025",
      month: "Февраль",
      text: "Дом сдан и введен в эксплуатацию. </br></br>\
      7 секция  Усиление грунтов основания окончено. Выполнено бетонирование фундаментной плиты. </br></br>\
      8 секция Монтаж фундаментных блоков подвала. </br></br>\
      9 секция Усиление грунтов основания выполнено на 70 %.",
    },
  ],
});

const state = reactive({
  data: [],
});

initBuildProgress();

/* Инициализация архива и фото*/
function initBuildProgress() {
  getArchives().then(async (response) => {
    archives = await response.json();

    activeInfo.activeArchive = archives[archives.length - 1];

    getPhotosWithDate(activeInfo.activeArchive).then(async (response) => {
      state.data = await response.json();
      state.data.forEach((item, index) => {
        activeInfo.month[index].exists = true;
      });
      activeInfo.loading = false;
    });
  });
  // swiperEl.value.slideTo(9);
}

/* Получаем архив*/
function getArchives() {
  activeInfo.loading = true;

  return fetch("/api_fs.php", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: "getArchives",
    }),
  });
}

/* Получаем фото*/
function getPhotosWithDate(year) {
  activeInfo.loading = true;
  return fetch("/api_fs.php", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: "getPhotosWithDate",
      year: year,
    }),
  });
}

const onClickActiveSelect = () => {
  activeInfo.activeSelect = !activeInfo.activeSelect;
};

const onClickActiveSelectStage = () => {
  activeInfo.activeSelectStage = !activeInfo.activeSelectStage;
};

const onClickActiveArchive = (nameArchive) => {
  activeInfo.activeArchive = nameArchive;
  activeInfo.activeMonth = 0;
  //if we have 2022 -> december because in archive 2022 only 1 month
  if (nameArchive == "2022") {
    activeInfo.month[0].name = "Декабрь";
  } else {
    activeInfo.month[0].name = "Январь";
  }
  activeInfo.month.forEach((item) => {
    item.exists = false;
  });
  /* */
  getPhotosWithDate(nameArchive).then(async (response) => {
    state.data = await response.json();
    activeInfo.loading = false;
    state.data.forEach((item, index) => {
      if (
        (activeInfo.activeArchive == "2023" &&
          activeInfo.activeStage == 1 &&
          index < 6) ||
        (activeInfo.activeArchive == "2023" &&
          activeInfo.activeStage == 2 &&
          index >= 6) ||
        activeInfo.activeArchive != "2023"
      ) {
        activeInfo.month[index].exists = true;
      }
    });
    setTimeout(() => {
      window.lozadInit();
    }, 300);
  });

  x = 0;
  onChangeProgress(activeInfo.activeMonth);
  document
    .querySelector(".construction-progress__left--wrapper")
    .scrollTo(x, 0);
};

const onClickActiveStage = (numberStage) => {
  activeInfo.activeStage = numberStage;
  activeInfo.stages.forEach((item) => {
    if (item.number == numberStage) {
      item.active = true;
      activeInfo.activeArchives = item.years;
      onClickActiveArchive(item.years.at(-1));
    } else {
      item.active = false;
    }
  });
};

const onSwiper = (swiper) => {
  swiperEl.value = swiper;
};

const onSlideChange = (swiper) => {
  let idPhoto = Number(swiper.slides[swiper.activeIndex].id.slice(3, 5));
  if (!(activeInfo.activeMonth + 1 === idPhoto)) {
    activeInfo.activeMonth = idPhoto - 1;
    onChangeProgress(activeInfo.activeMonth);
  }
};

const nextSlide = () => {
  swiperEl.value.slideNext();
};

const prevSlide = () => {
  swiperEl.value.slidePrev();
};

const onChangeProgress = (indexMonth) => {
  if (activeInfo.month[indexMonth].exists || indexMonth === 0) {
    activeInfo.month.forEach((item, index) => {
      if (index < indexMonth) {
        item.visited = true;
        item.active = false;
      } else if (index === indexMonth) {
        item.visited = false;
        item.active = true;
      } else {
        item.visited = false;
        item.active = false;
      }
    });
    let width =
      document.querySelector(".construction-progress__item").offsetWidth *
      (activeInfo.month.length - 1);
    x = (width / activeInfo.month.length - 1) * activeInfo.activeMonth - 100;
    document
      .querySelector(".construction-progress__left--wrapper")
      .scrollTo(x, 0);
  }
};

const onChangeMonth = (index) => {
  if (activeInfo.month[index].exists) {
    activeInfo.activeMonth = index;
    onChangeProgress(index);
    swiperEl.value.slideTo(
      swiperEl.value.slides.findIndex((element, i) => {
        if (Number(element.id.slice(3, 5)) - 1 === index) return i - 1;
      })
    );
  }
};

const initialSlide = () => {
  if (activeInfo.activeArchive === "2024") {
    return 57; ///активный слайд
  } else if (
    activeInfo.activeArchive === "2023" &&
    activeInfo.activeStage === 1
  ) {
    return 20;
  } else {
    return 5;
  }
};

const getDescription = () => {
  return activeInfo.descriptions.filter(
    (item) =>
      item.year == activeInfo.activeArchive &&
      item.month == activeInfo.month[activeInfo.activeMonth].name
  );
};
</script>
<template>
  <section class="construction-progress" id="constructionProgressLink">
    <div class="construction-progress__leaves">
      <img src="/src/images/constructionProgress/leaves.png" alt="" />
    </div>
    <div class="wrap">
      <div class="construction-progress__top">
        <div class="construction-progress__left--wrapper">
          <div class="construction-progress__left">
            <div
              v-for="(item, index) in activeInfo.month"
              :key="index"
              class="construction-progress__item"
            >
              <button
                class="construction-progress__circle"
                :class="[
                  item.visited && item.exists
                    ? 'construction-progress__circle--visited'
                    : '',
                  item.active && item.exists
                    ? 'construction-progress__circle--active'
                    : '',
                ]"
                @click="onChangeMonth(index)"
              ></button>
              <p
                class="construction-progress__date"
                :class="
                  (item.visited || item.active) && item.exists
                    ? 'construction-progress__date--active'
                    : ''
                "
              >
                {{ item.name }} {{ activeInfo.activeArchive }}
              </p>
            </div>
          </div>
        </div>
        <div class="construction-progress__right">
          <div class="construction-progress__selects">
            <div
              class="select"
              :class="activeInfo.activeSelectStage ? 'select--active' : ''"
              @click="onClickActiveSelectStage()"
            >
              <div
                class="select__title"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                Этап {{ activeInfo.activeStage }}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L15.0711 9.34315C15.4616 8.95262 15.4616 8.31946 15.0711 7.92893C14.6805 7.53841 14.0474 7.53841 13.6569 7.92893L8 13.5858L2.34315 7.92893C1.95262 7.53841 1.31946 7.53841 0.928932 7.92893C0.538407 8.31946 0.538407 8.95262 0.928932 9.34315L7.29289 15.7071ZM7 -4.95396e-08L7 15L9 15L9 4.95396e-08L7 -4.95396e-08Z"
                    fill="black"
                  />
                </svg>
              </div>
              <Transition>
                <div
                  v-if="activeInfo.activeSelectStage"
                  class="select__content"
                >
                  <div
                    v-for="(item, index) in activeInfo.stages"
                    :key="index"
                    class="select__item"
                    :class="
                      item.number == activeInfo.activeStage
                        ? 'select__item--active'
                        : ''
                    "
                    @click="onClickActiveStage(item.number)"
                  >
                    Этап {{ item.number }}
                  </div>
                </div>
              </Transition>
            </div>

            <div
              class="select"
              :class="activeInfo.activeSelect ? 'select--active' : ''"
              @click="onClickActiveSelect()"
            >
              <div
                class="select__title"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                Архив {{ activeInfo.activeArchive }}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L15.0711 9.34315C15.4616 8.95262 15.4616 8.31946 15.0711 7.92893C14.6805 7.53841 14.0474 7.53841 13.6569 7.92893L8 13.5858L2.34315 7.92893C1.95262 7.53841 1.31946 7.53841 0.928932 7.92893C0.538407 8.31946 0.538407 8.95262 0.928932 9.34315L7.29289 15.7071ZM7 -4.95396e-08L7 15L9 15L9 4.95396e-08L7 -4.95396e-08Z"
                    fill="black"
                  />
                </svg>
              </div>
              <Transition>
                <div v-if="activeInfo.activeSelect" class="select__content">
                  <div
                    v-for="(item, index) in activeInfo.activeArchives"
                    :key="index"
                    class="select__item"
                    :class="
                      item === activeInfo.activeArchive
                        ? 'select__item--active'
                        : ''
                    "
                    @click="onClickActiveArchive(item)"
                  >
                    Архив {{ item }}
                  </div>
                </div>
              </Transition>
            </div>
          </div>
          <h1
            class="construction-progress__title"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            ход строительства
          </h1>
        </div>
      </div>
      <div
        v-if="!activeInfo.loading"
        class="construction-progress__block"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <swiper
          class="construction-progress__swiper"
          :slides-per-view="1"
          :speed="700"
          :space-between="50"
          :slidesPerView="1"
          :initial-slide="initialSlide()"
          :breakpoints="{
            1025: {
              slidesPerView: 4,
            },
          }"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <template v-for="(item, index) in state.data" :key="index">
            <swiper-slide
              v-for="(photo, indexPhoto) in item.photo"
              :key="indexPhoto"
              :id="photo"
              class="construction-progress__slide"
            >
              <a
                :href="
                  '/uploads/' +
                  activeInfo.activeArchive +
                  '/' +
                  item.folder_date +
                  '/' +
                  photo
                "
                data-fancybox="gallery"
              >
                <img
                  class="lozad"
                  :data-src="
                    '/uploads/' +
                    activeInfo.activeArchive +
                    '/' +
                    item.folder_date +
                    '/' +
                    photo
                  "
                  alt=""
                />
              </a>
            </swiper-slide>
          </template>
        </swiper>
        <div class="navigation">
          <button class="navigation__button" @click="prevSlide">
            <svg
              width="132"
              height="16"
              viewBox="0 0 132 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.292892 7.23283C-0.0976257 7.62336 -0.0976257 8.25652 0.292892 8.64705L6.65685 15.011C7.04738 15.4015 7.68054 15.4015 8.07107 15.011C8.46159 14.6205 8.46159 13.9873 8.07107 13.5968L2.41422 7.93994L8.07107 2.28309C8.46159 1.89256 8.46159 1.2594 8.07107 0.868874C7.68054 0.478349 7.04738 0.478349 6.65685 0.868874L0.292892 7.23283ZM132 6.93994L1 6.93994V8.93994L132 8.93994V6.93994Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <button
            class="navigation__button navigation__button--next"
            @click="nextSlide"
          >
            <svg
              width="132"
              height="16"
              viewBox="0 0 132 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.292892 7.23283C-0.0976257 7.62336 -0.0976257 8.25652 0.292892 8.64705L6.65685 15.011C7.04738 15.4015 7.68054 15.4015 8.07107 15.011C8.46159 14.6205 8.46159 13.9873 8.07107 13.5968L2.41422 7.93994L8.07107 2.28309C8.46159 1.89256 8.46159 1.2594 8.07107 0.868874C7.68054 0.478349 7.04738 0.478349 6.65685 0.868874L0.292892 7.23283ZM132 6.93994L1 6.93994V8.93994L132 8.93994V6.93994Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
        <div
          v-if="getDescription().length"
          v-html="getDescription()[0].text"
          class="construction-progress__description"
        ></div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.construction-progress {
  &__slide {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;

    a {
      display: block;
      width: 100%;
      position: relative;
      cursor: pointer;

      &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.26),
            rgba(0, 0, 0, 0.26)
          ),
          url("/src/images/constructionProgress/increase.svg") no-repeat center;
        transition: opacity 0.3s;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  .construction-progress__description {
    margin-top: 30px;
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 4rem;
  }
}
</style>
