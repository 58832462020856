const apartments = {
    studio: [
      {
        img: "/src/images/apartment/0.webp",
        title: "Квартира - студия",
        square_from: "26.58",
        living_area: "22.59",
        floor: "2-9",
        deadline: "II кв. 2024г."
      },
      {
        img: "/src/images/apartment/0(1).webp",
        title: "Квартира - студия",
        square_from: "26.05",
        living_area: "21.9",
        floor: "2-9",
        deadline: "II кв. 2024г."
      },
      {
        img: "/src/images/apartment/0(2).webp",
        title: "Квартира - студия",
        square_from: "26.28",
        living_area: "19.48",
        floor: "2-9",
        deadline: "II кв. 2024г."
      },
      {
        img: "/src/images/apartment/0(3).webp",
        title: "Квартира - студия",
        square_from: "33.27",
        living_area: "24.91",
        floor: "2-9",
        deadline: "II кв. 2024г."
      }
    ],
    one_room: [
      {
        img: "/src/images/apartment/1.webp",
        title: "Квартира - 1 - комнатная",
        square_from: "48.97",
        living_area: "18.9",
        floor: "2-9",
        deadline: "II кв. 2024г."
      },
      {
        img: "/src/images/apartment/1(1).webp",
        title: "Квартира - 1 - комнатная",
        square_from: "48.06",
        living_area: "14.88",
        floor: "2-9",
        deadline: "II кв. 2024г."
      },
      {
        img: "/src/images/apartment/1(2).webp",
        title: "Квартира - 1 - комнатная",
        square_from: "41.27",
        living_area: "13.4",
        floor: "2-9",
        deadline: "II кв. 2024г."
      }
    ],
    two_room: [
      {
        img: "/src/images/apartment/2.webp",
        title: "Квартира - 2 - комнатная",
        square_from: "76.16",
        living_area: "42.34",
        floor: "2-9",
        deadline: "II кв. 2024г."
      },
      {
        img: "/src/images/apartment/2(1).webp",
        title: "Квартира - 2 - комнатная",
        square_from: "69.00",
        living_area: "40.78",
        floor: "2-9",
        deadline: "II кв. 2024г."
      }
    ],
    three_room: [
      {
        img: "/src/images/apartment/3.webp",
        title: "Квартира - 3 - комнатная",
        square_from: "79.51",
        living_area: "46.42",
        floor: "2-9",
        deadline: "II кв. 2024г."
      },
      {
        img: "/src/images/apartment/3(1).webp",
        title: "Квартира - 3 - комнатная",
        square_from: "84.31",
        living_area: "47.08",
        floor: "2-9",
        deadline: "II кв. 2024г."
      },
      {
        img: "/src/images/apartment/3(2).webp",
        title: "Квартира - 3 - комнатная",
        square_from: "79.45",
        living_area: "47.08",
        floor: "2-9",
        deadline: "II кв. 2024г."
      }
    ]
}

const TAB_TYPES = {['студия']: 'studio', ['1 - комнатная']: 'one_room', ['2 - комнатная']: 'two_room', ['3 - комнатная']: 'three_room' };

let parentlistTabs, listTabs, listApartment,
    previewImage, previewTitle, previewSquare, previewLivingArea,
    previewFloor, previewDeadline, apartemntModal, apartemntModalImage,
    overlay, magnifierButton, apartmentBtnClose, listPreviewApartments,
    btnPrev, btnNext, step, slides = null;

let activeSlide = 1;
let position = 0;

const defaultIndexActiveApartment = 0;

document.addEventListener('DOMContentLoaded', () => {
    parentlistTabs = document.querySelector(".apartment__right-list-buttons");
    listTabs = [...document.querySelectorAll(".apartment__right-list-button")];
    listApartment = document.querySelector(".apartment__right-list-img");
    listPreviewApartments = document.querySelector('.apartment__list');
    btnPrev = document.querySelector('.apartment__slider-button.prev');
    btnNext = document.querySelector('.apartment__slider-button.next');

    step = getPropertieValue(listPreviewApartments, 'width');

    apartemntModal = document.querySelector(".apartment-modal");
    apartemntModalImage = document.querySelector(".apartment-modal__img img");
    overlay = document.querySelector(".overlay");
    apartmentBtnClose = document.querySelector(".apartment-modal__btn-close");

    if (isWidthLessViewport(1024)) {
        renderApartmentsCurrentTab(apartments[getActiveType()]);
        setActiveApartmentToPreview();
    }

    if (!isWidthLessViewport(1024)) {
        renderAllReviewApartments();
        checkButtons();

        slides = [...document.querySelectorAll('.apartment__list .apartment__preview')];
    }


    listTabs.forEach((tab) => {
        tab.addEventListener("click", (event) => changeTab(event.target.textContent))
    });

    overlay.addEventListener("click", closeModal);
    apartmentBtnClose.addEventListener("click", closeModal);

    btnPrev.addEventListener('click', () => changeSlide('prev'));
    btnNext.addEventListener('click', () => changeSlide('next'));
})

function isNumber(value) {
    return typeof value === 'number'
}

function isWidthLessViewport(value) {
    return innerWidth >= value; 
}

function getPropertieValue(dom, propertie) {
    return +window.getComputedStyle(dom)[propertie].slice(0,-2);
}

function getItemByModifier(array, modifier, isIndex) {
  if (isIndex) {
    return [...array].findIndex((item) => item.classList.contains(modifier));
  }
  return [...array].find((item) => item.classList.contains(modifier));
}

function getTemplateApartment({ img }, isActive) {
  return `
    <li class="apartment__right-list-item ${isActive ? "active" : ""}">
        <div class="apartment__right-item-img">
            <img src="${img}" alt="preview-img">
        </div>                  
        <button class="apartment__right-button">
            <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1415_1218)">
                    <path d="M46.5699 44.4775L33.5794 31.4071C36.3612 28.0841 38.0357 23.7989 38.0357 19.1195C38.0357 8.55992 29.5213 0 18.9624 0C8.40353 0 -0.15625 8.55987 -0.15625 19.1187C-0.15625 29.6776 8.40362 38.2374 18.9624 38.2374C23.7821 38.2374 28.173 36.4492 31.5246 33.5066L44.4922 46.5557C45.0658 47.1292 45.9963 47.1292 46.5691 46.5557C47.1434 45.9814 47.1434 45.0517 46.5698 44.4774L46.5699 44.4775ZM18.9376 35.2501C10.0539 35.2501 2.78139 27.9776 2.78139 19.0938C2.78139 10.2101 10.0539 2.93759 18.9376 2.93759C27.8214 2.93759 35.0939 10.2101 35.0939 19.0938C35.0939 27.9776 27.8214 35.2501 18.9376 35.2501ZM26.2814 17.6251H20.4064V11.7501C20.4064 10.9393 19.7484 10.2813 18.9376 10.2813C18.1269 10.2813 17.4689 10.9393 17.4689 11.7501V17.6251H11.5939C10.7831 17.6251 10.1251 18.2831 10.1251 19.0938C10.1251 19.9046 10.7831 20.5626 11.5939 20.5626H17.4689V26.4376C17.4689 27.2483 18.1269 27.9063 18.9376 27.9063C19.7484 27.9063 20.4064 27.2483 20.4064 26.4376V20.5626H26.2814C27.0921 20.5626 27.7501 19.9046 27.7501 19.0938C27.7501 18.2831 27.0929 17.6251 26.2814 17.6251Z" fill="#A7A7A7"/>
                </g>
                <defs>
                    <clipPath id="clip0_1415_1218">
                    <rect width="47" height="47" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </button>
    </li>
    `;
}

function getTemplateApartmentReview({ img, title, square_from, living_area, floor, deadline }, data_type, isId) {
    return `
        <div class="apartment__preview" ${data_type && `data-type="${data_type}"`} ${isId && 'id="apartment__preview"'}>
            <div class="apartment__preview-img">
                <img src="${img}" alt="preview-img" />
            </div>
            <div class="apartment__preview-info">
                <div class="apartment__preview-title">
                    <p class="apartment__preview-title-text title">
                        ${title}
                    </p>
                    <p class="apartment__preview-title-text">
                    от <span class="square">${square_from}</span> кв.м.
                    </p>
                </div>
                <ul class="apartment__preview-list">
                    <li class="apartment__preview-item">
                        Жилая площадь - <span class="living_area">${living_area}</span> кв.м.
                    </li>
                    <li class="apartment__preview-item">
                        Этажность - <span class="floor">${floor}</span>
                    </li>
                    <li class="apartment__preview-item">
                        Срок сдачи - <span class="deadline">${deadline}</span>
                    </li>
                </ul>
            </div>
            <button class="apartment__preview-button">
                <span class="apartment__preview-label"> Узнать стоимость </span>
                <img src="src/images/apartment/arrow.svg" alt="arrow" />
            </button>
        </div>
    `
}

function getActiveType() {
  return getItemByModifier(listTabs, "active").getAttribute("data-type");
}

function renderApartmentsCurrentTab(apartmentArray) {
  listApartment.innerHTML = "";

  apartmentArray.forEach((apartment, idx) => {
    listApartment.innerHTML += getTemplateApartment(apartment, !idx);

    getTemplateApartment(apartment, !idx);
  });

  const apartmentItems = [...document.querySelectorAll(
    ".apartment__right-list-item"
  )];

  apartmentItems.forEach((apartment, idx) => {
        magnifierButton = apartment.querySelector('.apartment__right-button');

        magnifierButton.addEventListener('click', () => showModal(idx));

        apartment.addEventListener("click", (event) =>
            changeApartment(event, idx, apartmentItems)
        )
    }
  );
}

function renderAllReviewApartments() {
    for (const key in apartments) {
        apartments[key].forEach(apart => listPreviewApartments.innerHTML += getTemplateApartmentReview(apart, key))
    }
}

function calcPosition(type) {
    if (type === 'prev') {
        activeSlide -= 1
        return position += step;
    }

    if (type === 'next') {
        activeSlide += 1
        return position -= step;
    }
}

function setPositionForSlides() {
    slides.forEach(slide => {
        slide.style.transform = `translateX(${position}px)`;
    });
}

function checkButtons() {
    btnPrev.removeAttribute('disabled', true);
    btnNext.removeAttribute('disabled', true);
    
    if (activeSlide === 1) {
        return btnPrev.setAttribute('disabled', true)
    }

    if (activeSlide === slides.length) {
        return btnNext.setAttribute('disabled', true);
    }
}

function changeTab(type) {
    const index = listTabs.findIndex(tab => tab.textContent === type);

    toggleClasses(listTabs, index);

    if (isWidthLessViewport(1024)) {
        renderApartmentsCurrentTab(apartments[TAB_TYPES[type.toLowerCase()]]);
        return setActiveApartmentToPreview();
    }

    if (!isWidthLessViewport(1024)) {
        const index = slides.findIndex(slide => slide.getAttribute('data-type') === getActiveType());

        position = step * index * -1;
        activeSlide = index + 1;

        console.log(activeSlide);

        setPositionForSlides();
        checkButtons();
    }
}

function changeTabBySlide() {
    const slideType = slides[activeSlide - 1].getAttribute('data-type');

    if (slideType !== getActiveType()) {
        const index = listTabs.findIndex(tab => TAB_TYPES[tab.textContent.toLowerCase()] === slideType);

        toggleClasses(listTabs, index);
    }
}

function changeApartment(event, idx, apartmentItems) {
  const elem = event.target;
  const isActive = elem.classList.contains("active");

  if (!isActive) {
    setActiveApartmentToPreview(idx);
    toggleClasses(apartmentItems, idx);
  }
}

function changeSlide(type) {
    calcPosition(type);
    setPositionForSlides();
    changeTabBySlide();
    checkButtons();
}

function toggleClasses(array, receivedIndex) {
  [...array].forEach((item, idx) => {
    item.classList.remove("active");

    if (idx === receivedIndex) {
      item.classList.add("active");
    }
  });
}

function setActiveApartmentToPreview(idx) {
  const index = isNumber(idx) ? idx : defaultIndexActiveApartment;

  const previewApartment = document.querySelector('#apartment__preview');

  previewApartment && previewApartment.remove();

  listPreviewApartments.insertAdjacentHTML('beforebegin', getTemplateApartmentReview(apartments[getActiveType()][index], null, true))
}

/* Apartment Modal */

function showModal(idx) {
  const src = apartments[getActiveType()][idx].img;

  apartemntModalImage.setAttribute("src", src);

  apartemntModal.classList.add("active");
  overlay.classList.add("active");
}

function closeModal() {
  apartemntModal.classList.remove("active");
  overlay.classList.remove("active");
}

/* Apartment Modal */
