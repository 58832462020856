document.addEventListener('DOMContentLoaded', () => {
  //Burger
  const btnBurger = document.querySelectorAll('.header__burger');
  const btnBurgerClose = document.querySelectorAll('.page-menu__close');
  const itemBurgerClose = document.querySelectorAll('.page-menu__item');
  const burgerMenu = document.querySelectorAll('.page-menu__wrap');
  const body = document.body;
  const header = document.querySelector('.header');
  const wrapperMenu = document.querySelector('.page-menu__wrap');

  ///Open Menu
  btnBurger[0].addEventListener('click', function () {
    burgerMenu[0].classList.add('page-menu__wrap--active');
    body.style.overflow = 'hidden';
  });

  ///Close Menu
  btnBurgerClose[0].addEventListener('click', function () {
    burgerMenu[0].classList.remove('page-menu__wrap--active');
    body.style.overflow = 'auto';
  });

  ///Click on link navigation
  itemBurgerClose.forEach((elem) => {
    elem.addEventListener('click', function () {
      burgerMenu[0].classList.remove('page-menu__wrap--active');
      body.style.overflow = 'auto';
    });
  });

  ///Close Menu on empty space
  wrapperMenu.addEventListener('click', (e) => {
    if (e.target.matches('.page-menu__wrap--active')) {
      burgerMenu[0].classList.remove('page-menu__wrap--active');
      body.style.overflow = 'auto';
    }
  });

  //Scroll for header
  if (window.scrollY > 10) {
    header.classList.add('header--active');
  } else {
    header.classList.remove('header--active');
  }

  window.addEventListener('scroll', function () {
    if (window.scrollY > 10) {
      header.classList.add('header--active');
    } else {
      header.classList.remove('header--active');
    }
  });
});
