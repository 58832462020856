document.addEventListener('DOMContentLoaded', () => {
  const mapActive = document.querySelector('.location__click');

  mapActive.addEventListener('click', () => {
    mapActive.classList.add('location__click--hidden');
  });

  const category = document.querySelectorAll('.location__item');
  const activeCategory = [];

  ymaps.ready(function () {
    //Массив точек всех категорий
    const objectsCategory = [
      //детские сады
      {
        id: 1,
        data: [
          {
            coords: [47.236452, 39.799931],
            text: 'Детский сад № 130',
            picture: 'src/images/ymaps-images/kindergarten.svg',
          },
          {
            coords: [47.242095, 39.806853],
            text: 'Детский сад № 56 Семицветик',
            picture: 'src/images/ymaps-images/kindergarten.svg',
          },
          {
            coords: [47.240392, 39.804098],
            text: 'Детский сад № 55',
            picture: 'src/images/ymaps-images/kindergarten.svg',
          },
          {
            coords: [47.237835, 39.799877],
            text: 'Детский сад Голубые ели (частный)',
            picture: 'src/images/ymaps-images/kindergarten.svg',
          },
          {
            coords: [47.237703, 39.801568],
            text: 'Детский сад Жемчужина (частный)',
            picture: 'src/images/ymaps-images/kindergarten.svg',
          },
          {
            coords: [47.238654, 39.808232],
            text: 'Детский сад Кораблик № 280',
            picture: 'src/images/ymaps-images/kindergarten.svg',
          },
          {
            coords: [47.237715, 39.808345],
            text: 'Детский сад Искорка № 268',
            picture: 'src/images/ymaps-images/kindergarten.svg',
          },
          {
            coords: [47.236958, 39.807381],
            text: 'Детский сад № 257',
            picture: 'src/images/ymaps-images/kindergarten.svg',
          },
          {
            coords: [47.236702, 39.807837],
            text: 'Детский сад Гармония (частный)',
            picture: 'src/images/ymaps-images/kindergarten.svg',
          },
          {
            coords: [47.237574, 39.809357],
            text: 'Детский сад № 209',
            picture: 'src/images/ymaps-images/kindergarten.svg',
          },
        ],
      },

      //школы
      {
        id: 2,
        data: [
          {
            coords: [47.241689, 39.803883],
            text: 'Школа № 22',
            picture: 'src/images/ymaps-images/school.svg',
          },
          {
            coords: [47.237357, 39.816513],
            text: 'Школа № 94',
            picture: 'src/images/ymaps-images/school.svg',
          },
          {
            coords: [47.23078, 39.805338],
            text: 'Школа № 7',
            picture: 'src/images/ymaps-images/school.svg',
          },
          {
            coords: [47.238404, 39.820304],
            text: 'Школа № 81',
            picture: 'src/images/ymaps-images/school.svg',
          },
          {
            coords: [47.241049, 39.815785],
            text: 'Спортивная школа № 11',
            picture: 'src/images/ymaps-images/school.svg',
          },
          {
            coords: [47.239909, 39.802572],
            text: 'Детский клуб Старт',
            picture: 'src/images/ymaps-images/school.svg',
          },
          {
            coords: [47.240795, 39.802587],
            text: 'Английская школа Пилот, ул. Вересаева 101/6',
            picture: 'src/images/ymaps-images/school.svg',
          },
          {
            coords: [47.242205, 39.814819],
            text: 'Детская школа искусств №8',
            picture: 'src/images/ymaps-images/school.svg',
          },
          {
            coords: [47.231033, 39.781634],
            text: 'Дворец творчества детей и молодежи',
            picture: 'src/images/ymaps-images/school.svg',
          },
          {
            coords: [47.229897, 39.76721],
            text: 'Музыкальная школа им. Гнесина',
            picture: 'src/images/ymaps-images/school.svg',
          },
          {
            coords: [47.24053, 39.835241],
            text: 'Детская джазовая школа им. Кима Назаретова',
            picture: 'src/images/ymaps-images/school.svg',
          },
        ],
      },

      //кафе и рестораны
      {
        id: 3,
        data: [
          {
            coords: [47.241689, 39.798628],
            text: 'KFC',
            picture: 'src/images/ymaps-images/cafe.svg',
          },
          {
            coords: [47.241615, 39.801754],
            text: 'Мицуна',
            picture: 'src/images/ymaps-images/cafe.svg',
          },
          {
            coords: [47.237015, 39.808465],
            text: 'Орбита',
            picture: 'src/images/ymaps-images/cafe.svg',
          },
          {
            coords: [47.236415, 39.813019],
            text: 'Мада',
            picture: 'src/images/ymaps-images/cafe.svg',
          },
          {
            coords: [47.242339, 39.801255],
            text: 'Twin Bean',
            picture: 'src/images/ymaps-images/cafe.svg',
          },
          {
            coords: [47.241638, 39.800097],
            text: 'Соседи',
            picture: 'src/images/ymaps-images/cafe.svg',
          },
          {
            coords: [47.23934, 39.798664],
            text: 'Кофейня',
            picture: 'src/images/ymaps-images/cafe.svg',
          },
          {
            coords: [47.234862, 39.79956],
            text: '3 Пирога и Пенный край',
            picture: 'src/images/ymaps-images/cafe.svg',
          },
          {
            coords: [47.235362, 39.803489],
            text: 'Золотой бык',
            picture: 'src/images/ymaps-images/cafe.svg',
          },
          {
            coords: [47.236205, 39.810628],
            text: 'Вкуснолюбов',
            picture: 'src/images/ymaps-images/cafe.svg',
          },
          {
            coords: [47.236942, 39.811312],
            text: 'Золотой колос',
            picture: 'src/images/ymaps-images/cafe.svg',
          },
        ],
      },

      //магазины
      {
        id: 4,
        data: [
          {
            coords: [47.240808, 39.79976],
            text: 'Продуктовый магазин',
            picture: 'src/images/ymaps-images/magazine.svg',
          },
          {
            coords: [47.237187, 39.799197],
            text: 'Продуктовый магазин',
            picture: 'src/images/ymaps-images/magazine.svg',
          },
          {
            coords: [47.236826, 39.799148],
            text: 'Продуктовый магазин',
            picture: 'src/images/ymaps-images/magazine.svg',
          },
          {
            coords: [47.234879, 39.8002],
            text: 'Продуктовый магазин',
            picture: 'src/images/ymaps-images/magazine.svg',
          },
          {
            coords: [47.236954, 39.805185],
            text: 'Продуктовый магазин',
            picture: 'src/images/ymaps-images/magazine.svg',
          },
          {
            coords: [47.23556, 39.805382],
            text: 'Продуктовый магазин',
            picture: 'src/images/ymaps-images/magazine.svg',
          },
          {
            coords: [47.239203, 39.811939],
            text: 'Продуктовый магазин',
            picture: 'src/images/ymaps-images/magazine.svg',
          },
          {
            coords: [47.238469, 39.806687],
            text: 'Продуктовый магазин',
            picture: 'src/images/ymaps-images/magazine.svg',
          },
          //
          // {
          //   coords: [47.237186, 39.799194],
          //   text: 'Продуктовый магазин',
          //   picture: 'src/images/ymaps-images/magazine.svg',
          // },
          // {
          //   coords: [47.234879, 39.8002],
          //   text: 'Продуктовый магазин',
          //   picture: 'src/images/ymaps-images/magazine.svg',
          // },
          // {
          //   coords: [47.239205, 39.811941],
          //   text: 'Продуктовый магазин',
          //   picture: 'src/images/ymaps-images/magazine.svg',
          // },
          // {
          //   coords: [47.236954, 39.805186],
          //   text: 'Продуктовый магазин',
          //   picture: 'src/images/ymaps-images/magazine.svg',
          // },
          // {
          //   coords: [47.235579, 39.805384],
          //   text: 'Продуктовый магазин',
          //   picture: 'src/images/ymaps-images/magazine.svg',
          // },
          // {
          //   coords: [47.239206, 39.811939],
          //   text: 'Продуктовый магазин',
          //   picture: 'src/images/ymaps-images/magazine.svg',
          // },
          // {
          //   coords: [47.238483, 39.806686],
          //   text: 'Продуктовый магазин',
          //   picture: 'src/images/ymaps-images/magazine.svg',
          // },
        ],
      },

      //Cпорт
      {
        id: 5,
        data: [
          {
            coords: [47.232132, 39.778721],
            text: 'Арбат-фитнес',
            picture: 'src/images/ymaps-images/sport.svg',
          },
          {
            coords: [47.237168, 39.801188],
            text: ' Jumpman',
            picture: 'src/images/ymaps-images/sport.svg',
          },
          {
            coords: [47.235516, 39.804979],
            text: 'Актив',
            picture: 'src/images/ymaps-images/sport.svg',
          },
        ],
      },

      //медицина
      {
        id: 6,
        data: [
          {
            coords: [47.24403, 39.800037],
            text: 'Хеликс',
            picture: 'src/images/ymaps-images/med.svg',
          },
          {
            coords: [47.241609, 39.79994],
            text: 'INVITRO',
            picture: 'src/images/ymaps-images/med.svg',
          },
          {
            coords: [47.235516, 39.804979],
            text: 'Евродон',
            picture: 'src/images/ymaps-images/med.svg',
          },
          {
            coords: [47.244013, 39.800011],
            text: 'АВЕНЮ',
            picture: 'src/images/ymaps-images/med.svg',
          },
          {
            coords: [47.23565, 39.806407],
            text: 'Детская клиника Плюс',
            picture: 'src/images/ymaps-images/med.svg',
          },
          {
            coords: [47.242289, 39.801889],
            text: 'Наше Здоровье',
            picture: 'src/images/ymaps-images/med.svg',
          },
          {
            coords: [47.235962, 39.807775],
            text: 'Гераци',
            picture: 'src/images/ymaps-images/med.svg',
          },
          {
            coords: [47.259497, 39.806093],
            text: 'Медсанчасть',
            picture: 'src/images/ymaps-images/med.svg',
          },
          {
            coords: [47.229957, 39.778537],
            text: 'Городская больница № 6',
            picture: 'src/images/ymaps-images/med.svg',
          },
        ],
      },

      //торговые центры
      {
        id: 7,
        data: [
          {
            coords: [47.25662, 39.80382],
            text: 'Аэровокзал',
            picture: 'src/images/ymaps-images/tc.svg',
          },
          {
            coords: [47.236085, 39.810522],
            text: 'Торговый центр Пятый элемент',
            picture: 'src/images/ymaps-images/tc.svg',
          },
          {
            coords: [47.239187, 39.798529],
            text: 'Рынок Вересаева',
            picture: 'src/images/ymaps-images/tc.svg',
          },
          {
            coords: [47.244125, 39.766957],
            text: 'Панорама',
            picture: 'src/images/ymaps-images/tc.svg',
          },
          {
            coords: [47.239109, 39.827294],
            text: 'Мир',
            picture: 'src/images/ymaps-images/tc.svg',
          },
        ],
      },
    ];

    let myPoints = [];

    objectsCategory.forEach((item) => {
      myPoints.push(...item.data);
    });

    let myMap = new ymaps.Map('YMapsID', {
      center: [47.238964, 39.799551],
      zoom: 16,
      controls: ['zoomControl'],
    });
    myMap.behaviors.disable('scrollZoom');

    let myCircle = new ymaps.Circle(
      [[47.238964, 39.799551], 100],
      {},
      {
        fillColor: 'rgba(175, 66, 177, 0.15)',
        strokeOpacity: 0,
        strokeWidth: 0,
      }
    );

    var placemarkJK = new ymaps.Placemark(
      [47.238964, 39.799551],
      {},
      {
        iconLayout: 'default#imageWithContent',
        iconImageHref: 'src/images/map.png',
        iconImageSize: [61, 61],
        iconImageOffset: [-30, -30],
      }
    );

    var placemarkMB = new ymaps.Placemark(
      [47.238964, 39.799551],
      {},
      {
        iconLayout: 'default#imageWithContent',
        iconImageHref: 'src/images/map.png',
        iconImageSize: [40, 40],
        iconImageOffset: [-20, -20],
      }
    );

    // Создаем коллекцию.
    let myCollection = new ymaps.GeoObjectCollection();

    fillMapPoints();
    // Заполняем коллекцию данными.
    function fillMapPoints() {
      for (let i = 0, l = myPoints.length; i < l; i++) {
        let point = myPoints[i];

        let element = new ymaps.Placemark(
          point.coords,
          {
            hintContent: point.text,
          },
          {
            iconLayout: 'default#imageWithContent',
            iconImageHref: point.picture,
            iconImageSize: [43, 43],
            iconImageOffset: [-21, -21],
          }
        );
        myCollection.add(element);
      }
    }

    category.forEach((elem) => {
      elem.addEventListener('click', () => filterMyPoints(elem));
    });

    function filterMyPoints(elem) {
      const idCategory = elem.dataset.id;

      elem.classList.toggle('location__item--active');

      if (activeCategory.includes(idCategory)) {
        const index = activeCategory.findIndex(
          (element) => element === idCategory
        );
        activeCategory.splice(index, 1);
      } else {
        activeCategory.push(idCategory);
      }

      //clear points
      myPoints.length = 0;

      //fill MyPoints
      if (activeCategory.length > 0) {
        activeCategory.forEach((elem) => {
          let found = objectsCategory.find((e) => e.id == elem);
          myPoints.push(...found.data);
        });
      }

      //delete points on current points
      myCollection.removeAll();

      //fill new points
      fillMapPoints();
    }

    if (window.innerWidth > 500) {
      myMap.geoObjects.add(placemarkJK);
    } else {
      myMap.geoObjects.add(placemarkMB);
    }
    myMap.geoObjects.add(myCircle);

    // Добавляем коллекцию меток на карту.
    myMap.geoObjects.add(myCollection);
  });
});
